import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import {
  breakpoints,
  flex,
  font,
  padding,
} from '@prototyp/gatsby-plugin-gumball/utils';
import { grid } from '@prototyp/gatsby-plugin-gumball/utils/grid';
import { ellipsis, flexCenter, scrollbar } from 'style/global.styles';
import * as btn from 'modules/app/styles/Button.styles';

export const openingsGrid = css`
  padding-top: ${spacing(40)};

  @media ${breakpoints.large} {
    padding-top: 0;
    grid-gap: calc(var(--unit) * 18);
    ${grid.display};
    ${grid.template.cols12};
  }
`;

export const sectionWrapper = css`
  @media ${breakpoints.smallMax} {
    margin-bottom: ${spacing(32)};
  }
  @media ${breakpoints.large} {
    margin: calc(var(--unit) * 20) 0;
  }
`;

export const sectionGridWrapper = css`
  ${sectionWrapper};

  @media ${breakpoints.large} {
    ${grid.display};
    ${grid.template.cols9};
    ${grid.gap.huge};
  }
`;

export const submitSection = css`
  margin: calc(var(--unit) * 10) 0;

  @media ${breakpoints.large} {
    margin: calc(var(--unit) * 18) 0;
    border-left: 1px solid hsl(var(--color-borderLight-8));
  }
`;

export const submitSectionContainer = css`
  @media ${breakpoints.large} {
    position: sticky;
    top: calc(var(--unit) * 18);
    margin-left: calc(var(--unit) * 18);
  }
`;

export const subSectionSpacing = css`
  margin-top: calc(var(--unit) * 8);
`;

export const loading = css`
  margin-top: calc(var(--unit) * 20);
`;

export const companyOpeningsListContainer = css`
  margin: 0 0 calc(var(--unit) * 30);
`;

export const companyOpeningsListSection = css`
  padding-top: calc(var(--unit) * 10);

  @media ${breakpoints.large} {
    padding: calc(var(--unit) * 20) 0;
  }

  &:nth-of-type(odd) {
    background-color: hsl(var(--color-white-11));
  }
`;

export const headingWrapper = css`
  @media ${breakpoints.smallMax} {
    > * + * {
      margin-top: ${spacing(12)};
    }
  }

  @media ${breakpoints.medium} {
    ${flex.display.flex};
    ${flex.justify.between};
  }
`;

export const companyOpeningsListSubtitle = css`
  font-size: var(--font-size-tiny);
  line-height: var(--line-height-tiny);
  color: hsl(var(--color-grayscale-4));
  margin: calc(var(--unit) * 2) 0 0 0;
`;

export const pipelineWrapper = css`
  display: flex;
  overflow-y: auto;

  ${scrollbar};
`;

export const pipelineWrapperDisabled = css`
  ${pipelineWrapper};
  pointer-events: none;
`;

export const companyOpeningsPipeline = css`
  display: flex;

  &:last-child {
    svg {
      display: none;
    }
  }

  &:first-of-type {
    div {
      padding-left: 0;
    }
  }

  div {
    max-width: 140px;
    padding: 0 20px 24px;

    &:hover {
      padding-bottom: 20px;
      border-bottom: 2px solid #292e32;
      color: black;

      p {
        color: black;
      }
    }
  }

  :hover {
    cursor: pointer;
  }
`;

export const companyOpeningsPipelineActive = css`
  ${companyOpeningsPipeline};

  div {
    padding-bottom: 20px;
    border-bottom: 2px solid #292e32;
    color: black;

    p {
      color: black;
    }
  }
`;

export const companyOpeningsPipelineNumber = css`
  transition: color 0.2s;
  ${font.fluid.medium};
  color: hsl(var(--color-grayscale-6));
`;

export const companyOpeningsPipelineNumberAlt = css`
  ${companyOpeningsPipelineNumber};
  color: #77d369;
  font-weight: var(--font-weight-bold);
`;

export const companyOpeningsPipelineText = css`
  transition: color 0.2s;
  margin-bottom: calc(var(--unit) * 2);
  color: hsl(var(--color-grayscale-4));
  ${ellipsis};
`;

export const companyOpeningsPipelineTextAlt = css`
  ${companyOpeningsPipelineText};
  color: #77d369;
`;

export const companyOpeningTitle = css`
  ${font.size.large}
  ${font.weight.bold}
  margin: 0;
  flex: 1;
  color: hsl(var(--color-text-1));
  max-width: 640px;

  &:hover {
    text-decoration: underline;
  }
`;

export const companyOpeningTitleDisabled = css`
  ${companyOpeningTitle};
  pointer-events: none;
`;

export const submitLoadingIcon = css`
  width: calc(var(--unit) * 5);
  height: calc(var(--unit) * 5);
`;

export const submitSectionEdited = css`
  svg {
    width: 16px;
    height: 16px;
  }

  & > p {
    margin: calc(var(--unit) * 5) 0 calc(var(--unit) * 15) 0;
    color: hsl(var(--color-borderDark-5));
  }

  & > div {
    display: flex;
    align-items: center;
  }
`;

export const publishingSection = css`
  margin: calc(var(--unit) * 5) 0 calc(var(--unit) * 15) 0;
  color: hsl(var(--color-textLight-7));
`;

export const applicationWrapper = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dde1e3;
  margin: calc(var(--unit) * 10) 0 calc(var(--unit) * 8);
`;

export const viewAll = css`
  ${font.size.medium};
  margin-left: calc(var(--unit) * 15);
  color: hsl(var(--color-grayscale-6));
  transition: color 0.2s;

  &:hover {
    cursor: pointer;
    color: hsl(var(--color-text-1));
  }
`;

export const viewAllActive = css`
  ${viewAll};
  color: hsl(var(--color-text-1));
`;

export const profileUploadFix = css`
  margin-top: calc(var(--unit) * 14) !important;
`;

export const pagination = css`
  padding-top: calc(var(--unit) * 10);

  @media ${breakpoints.medium} {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
`;

export const perPage = css`
  ${flexCenter};
  margin-top: calc(var(--unit) * 3);

  @media ${breakpoints.medium} {
    display: block;
    margin-left: auto;
    margin-top: 0;
  }
`;

export const noOpenings = css`
  max-width: ${spacing(416)};
  margin: ${spacing(120)} auto 0;

  > p {
    color: hsl(var(--color-grayscale-6));
    margin: ${spacing(8)} 0 ${spacing(40)};
  }

  > a {
    ${btn.root};
    ${btn.primary};
    ${btn.small};
  }

  > div {
    width: ${spacing(80)};
    height: ${spacing(80)};
    border-radius: 50%;
    background: hsl(var(--color-note-10));
    ${flexCenter};
    margin-bottom: ${spacing(20)};
  }
`;

export const noOpeningsDisabled = css`
  ${noOpenings};

  > a {
    pointer-events: none;
    opacity: 0.6;
  }
`;

export const noOpeningDash = css`
  ${noOpenings};
  margin: 0;
  margin-top: calc(var(--unit) * 30);
`;

export const openingHeader = css`
  ${grid.display};
  ${grid.gap.xxxlrg};
  ${padding.bottom.huge};

  @media ${breakpoints.medium} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const wrapper = css`
  @media ${breakpoints.large} {
    ${flex.display.flex};
    ${flex.justify.between};
  }
`;

export const inner = css`
  margin-bottom: ${spacing(16)};

  @media ${breakpoints.smallMax} {
    > * + * {
      margin-top: ${spacing(16)};
    }
  }

  @media ${breakpoints.medium} {
    ${flex.display.flex};
    ${flex.justify.between};
  }
`;

export const skeletonItem = css`
  min-height: ${spacing(60)};
  border-radius: ${spacing(3)};
`;

export const matchingLink = css`
  ${btn.root};
  ${btn.outline};
  ${btn.small};
  margin-right: ${spacing(60)};
`;

export const matchingLinkDisabled = css`
  ${matchingLink};
  pointer-events: none;
  opacity: 0.6;
`;

export const linkDisabled = css`
  pointer-events: none;
  opacity: 0.6;
`;
